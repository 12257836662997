import _ from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LinesEllipsis from 'react-lines-ellipsis'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Link } from '@reach/router'
import { getUrlMedia, getRoute } from '../../helpers'
import {
  CardWithPicture,
  Spinner,
  BlockLinks,
  Cta,
  Button,
  Seo,
} from '../../components'
import { ImageRoundCorners } from '../../components/frames-images'
import './about-us-entity-styles.scss'
import ctaImage from '../../assets/images/about-us/pacificcross_aboutgroup_cta.jpeg'

const AboutUsEntityView = ({ data, loading }) => {
  const [lessText, setLessText] = useState(true)
  const { t, i18n } = useTranslation()
  const breakpoints = useBreakpoint()
  if (loading) {
    return <Spinner loading={loading} />
  }
  const createText = (txt) => {
    const newArraytext = txt.split('/')
    return newArraytext.length > 1
      ? `<h2>${newArraytext[0]}</h2><p>/${newArraytext[1]}`
      : `<h2>${newArraytext[0]}</h2`
  }
  return (
    <div className="aboutUsEntity__container container-fluid r-0 p-0">
      <Seo title={`${t('seo.title')} About us - Group `} />
      <div className="container-xxl">
        <div className="row aboutUsEntity__header">
          <div className="col-12 col-sm-7">
            <ImageRoundCorners
              roundBottomRightHoz
              src={getUrlMedia(_.get(data, 'section_1[0].image'))}
            />
          </div>
          <div className="col-12 col-sm-5">
            <div className="aboutUsEntity__header__content">
              <h1 className="page-title">
                {_.get(data, 'section_1[0].title')}
              </h1>
              {lessText ? (
                <div onClick={() => setLessText(!lessText)} className="">
                  <LinesEllipsis
                    text={_.get(data, 'section_1[0].description')}
                    maxLine={breakpoints.xs ? '3' : '1000'}
                    ellipsis={' Read more'}
                    trimRight
                    basedOn="letters"
                  />
                </div>
              ) : (
                <p>{_.get(data, 'section_1[0].description')}</p>
              )}
            </div>
          </div>
        </div>
        <div className="aboutUsEntity__statistics">
          {_.map(data.statistics, (item, idx) => (
            <div
              className="aboutUsEntity__statistics__col col-6 col-sm-3"
              key={item.text + idx}
            >
              <div
                className="text-numbers"
                dangerouslySetInnerHTML={{
                  __html: createText(item.number),
                }}
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      {process.env.GATSBY_HAS_PARTNERS === 'true' ? (
        <div className="aboutUsEntity__partners">
          <div className="container-xxl">
            <h2 className="aboutUsEntity__title">
              {t('aboutUsEntity.ourpartners')}
            </h2>
            <div className="aboutUsEntity__partners__row">
              {_.map(data.our_partners, (value, idx) => (
                <div className="aboutUsEntity__partners__col" key={idx}>
                  <Link to={getRoute({
                    locale: i18n.language,
                    route: '/about-partners/fakeurl',
                  })}>
                    <img src={getUrlMedia(value.partners_logo)} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: '200px' }} />
      )}
      <div className="aboutUsEntity__offices">
        <BlockLinks
          data={_.get(data, 'offices')}
          roundTopRight={true}
          titleDown={true}
          url={'/our-offices'}
          title={t('aboutUsEntity.offices')}
          t={t}
          addon
        />
      </div>
      <div className="aboutUsEntity__news">
        <div className="container-xxl">
          <span className="aboutUsEntity__tag">
            {t('aboutUsEntity.highlighted')}
          </span>
          <div className="row">
            {_.map(data.news, (value, idx) => (
              <div className="col-12 col-sm-4 py-4" key={idx}>
                <CardWithPicture
                  parentLink="news"
                  pictureDisplace={true}
                  title={null}
                  value={value}
                  data={value}
                  i18n={i18n}
                />
              </div>
            ))}
          </div>
          <div className="aboutUsEntity__news__btn">
            <Button
              text={t('aboutUsEntity.btnText')}
              link={'/news-events/'}
              outline={true}
            />
          </div>
        </div>
      </div>
      <div className="container-xxl p-0">
        <Cta
          withAppend={true}
          img={ctaImage}
          info={{
            buttonText: t('footer.contact.button'),
          }}
        />
      </div>
    </div>
  )
}

export default AboutUsEntityView
