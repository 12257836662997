import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import AboutUsEntityView from './about-us-entity-view'

const AboutUsEntityController = () => {
  const [page, setPage] = useState([])
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const getPage = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        locale: i18n.language,
        slug: 'entity',
      },
    }).then((resp) => {
      setPage(resp.data.content)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getPage()
  }, [i18n.language])

  const viewProps = {
    loading,
    data: page,
    t,
  }
  return <AboutUsEntityView {...viewProps} />
}

export default AboutUsEntityController
